import ApiService from "./api.service";

const DataImportService = {
  async getDataImport() {
    return await ApiService.commonGETRequest("/import/");
  },
  async updateDataImport(data, upFunc, canFunc) {
    return await ApiService.commonPUTRequest(
      "/import/",
      {},
      data,
      {
        "Content-Type": "multipart/form-data",
      },
      upFunc,
      canFunc
    );
  },
  async deleteDataImport() {
    return await ApiService.commonDELETERequest("/import/");
  },
};

export default DataImportService;
