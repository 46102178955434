<template>
  <Subview
    :contentPadding="false"
    class="main-content dataimport"
    customContentPadding="px-2"
  >
    <v-layout
      slot="subview-content"
      :class="$vuetify.breakpoint.smAndUp && 'settings-view-tab-content'"
      :style="{
        maxHeight:
          $vuetify.breakpoint.smAndUp && `calc(100vh - 176px) !important`,
      }"
    >
      <v-layout
        v-if="dataImport.status === null"
        align-center
        column
        class="mt-4"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          width="7"
          size="60"
          class="ma-3"
        ></v-progress-circular>
        <DefaultSubtitle class="ma-3">Wczytuję dane...</DefaultSubtitle>
      </v-layout>
      <v-layout column v-else :style="{ height: '100%' }">
        <DefaultSubtitle class="mx-4 mb-4" color="primary"
          >Importuj dane medyczne</DefaultSubtitle
        >
        <DefaultLabel class="mx-4 mb-4">
          Prześlij dane medyczne (dane i karty pacjentów, informacje o
          wizytach), które chcesz zaimportować do Booksy Med. Import danych może
          potrwać do 10 dni roboczych. Aktualny status importu będzie widoczny w
          tej zakładce.
        </DefaultLabel>
        <DefaultSubtitle class="mx-4 my-4" color="primary"
          >Wybierz opcję importu</DefaultSubtitle
        >
        <FileImportForm
          :value.sync="dataImport"
          :loading="loading"
          @refresh="getDataImport"
          @reset="() => deleteDataImport(false)"
          @success="handleSuccess"
          @error="handleError"
        ></FileImportForm>
        <LinkImportForm
          :value.sync="dataImport"
          :loading="loading"
          @refresh="getDataImport"
          @success="handleSuccess"
          @error="handleError"
        ></LinkImportForm>
        <template v-if="dataImport.state">
          <StatusForm :value.sync="dataImport"></StatusForm>
          <DefaultButton class="mt-4 mx-4" @click.prevent="deleteDataImport"
            >Anuluj Import</DefaultButton
          >
        </template>
      </v-layout>
    </v-layout>
  </Subview>
</template>

<script>
import DataImportService from "@/services/dataimport.service";
import Subview from "@/components/Subview";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

export default {
  mixins: [ProxyCRUDMixin],
  data() {
    return {
      dataImport: {
        mode: null,
        source: null,
        state: null,
        file: null,
        file_password: null,
        link: null,
        link_password: null,
      },
    };
  },
  components: {
    Subview,
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    InvoiceDetailsForm: () =>
      import("@/components/forms/subscriptions/InvoiceDetailsForm"),
    SubscriptionForm: () =>
      import("@/components/forms/subscriptions/SubscriptionForm"),
    FileImportForm: () =>
      import("@/components/forms/dataimport/FileImportForm"),
    LinkImportForm: () =>
      import("@/components/forms/dataimport/LinkImportForm"),
    StatusForm: () => import("@/components/forms/dataimport/StatusForm"),
  },
  methods: {
    determineMode() {
      if (this.dataImport.file || this.dataImport.file_password) {
        this.$set(this.dataImport, "mode", "file");
      } else if (this.dataImport.link || this.dataImport.link_password) {
        this.$set(this.dataImport, "mode", "link");
      }
      return this.dataImport;
    },
    deleteDataImport(replace=true) {
      if (replace) {
        this.dataImport = {
          mode: null,
          source: null,
          state: null,
          file: null,
          file_password: null,
          link: null,
          link_password: null,
        };
      }
      return DataImportService.deleteDataImport()
        .then((data) => {
          if (replace) {
            this.dataImport = data;
          }
          return this.dataImport;
        })
        .catch((error) => {
          this.handleError("Coś poszło nie tak.");
        });
    },
    getDataImport() {
      this.beforeRequest();
      return DataImportService.getDataImport()
        .then((data) => {
          this.dataImport = data;
          this.reset();
          return this.dataImport;
        })
        .then(this.determineMode)
        .catch(() => {
          this.handleError("Coś poszło nie tak.");
        });
    },
  },
  async created() {
    this.getDataImport()
      .then(this.determineMode)
      .catch(() => {
        this.handleError("Coś poszło nie tak.");
      });
  },
};
</script>

<style lang="scss" scoped>
.settings-view-tab-content {
  background-color: transparent;
  overflow: auto !important;
}
</style>
